





































import {
    computed,
    defineComponent,
    reactive,
    toRefs,
} from "@vue/composition-api"
import AppNavigationMenu from "@/AppNavigationMenu.vue"
import AppLoginState from "@/AppLoginState.vue"
import AppDialog from "@/AppDialog.vue"
import { title, backNav, layout } from "@/services/AppBarService"
import { router } from "@/services/Router"
import { now } from "@/services/TimeService"
import { timeFormatter } from "@/utilities/Formatter"
import { env } from "@/env"

export default defineComponent({
    name: "App",
    components: {
        AppNavigationMenu,
        AppLoginState,
        AppDialog,
    },
    setup() {
        const state = reactive({
            drawer: false,
        })

        const titleText = computed(() =>
            title.value ? `${env.TITLE} \\ ${title.value}` : title.value
        )
        function navBack() {
            router.back()
        }
        return {
            titleText,
            ...toRefs(state),
            title,
            backNav,
            navBack,
            now,
            timeFormatter,
            layout,
        }
    },
})
