


























import {
    computed,
    defineComponent,
    reactive,
    toRefs,
} from "@vue/composition-api"
import { userProfile, login, logout } from "@/services/AuthService"
export default defineComponent({
    setup() {
        const state = reactive({})
        const name = computed(() => userProfile.value?.name)
        return {
            ...toRefs(state),
            name,
            login,
            logout,
            id: userProfile.value?.id,
        }
    },
})
