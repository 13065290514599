import { computed, reactive } from "@vue/composition-api"
import { Lock } from "@/utilities/Lock"

export enum DialogLevel {
    Info = "Info",
    Success = "Success",
    Warning = "Warning",
    Error = "Error"
}

export enum DialogButtonType {
    None,
    Confirm,
    ConfirmCancel,
    YesNo,
    YesNoCancel
}

export type DialogButtonTypeOrCustom = DialogButtonType | string[]

export enum ButtonResult {
    /** 確認按鈕 */
    Confirm = 1,
    /** 取消按鈕 */
    Cancel = 2,
    /** 是按鈕 */
    Yes = 4,
    /** 否按鈕 */
    No = 8
}

export interface DialogResult {
    button?: ButtonResult | string
}

const state = reactive({
    on: false,
    title: "",
    level: DialogLevel.Info,
    buttons: ["確認"],
    message: "",
    resolve: null as ((value: DialogResult) => void) | null
})

const showLock = new Lock()

export const dialogState = computed(() => ({
    on: state.on,
    title: state.title,
    type: state.level,
    buttons: state.buttons,
    message: state.message
}))

function setDialogButtons(buttonType?: DialogButtonTypeOrCustom) {
    switch (buttonType) {
        case DialogButtonType.None:
            state.buttons = []
            break
        case undefined:
        case DialogButtonType.Confirm:
            state.buttons = ["確認"]
            break
        case DialogButtonType.ConfirmCancel:
            state.buttons = ["確認", "取消"]
            break
        case DialogButtonType.YesNo:
            state.buttons = ["是", "否"]
            break
        case DialogButtonType.YesNoCancel:
            state.buttons = ["是", "否", "取消"]
            break
        default:
            state.buttons = buttonType
    }
}

export function closeDialog(button?: string) {
    if (state.resolve)
        state.resolve({
            button:
                button === "確認"
                    ? ButtonResult.Confirm
                    : button === "取消"
                    ? ButtonResult.Cancel
                    : button === "是"
                    ? ButtonResult.Yes
                    : button === "否"
                    ? ButtonResult.No
                    : button
        })
    state.on = false
    state.resolve = null
    showLock.release()
}

export async function showDialog(
    title: string,
    message = "",
    type = DialogLevel.Info,
    buttonType: DialogButtonTypeOrCustom = DialogButtonType.Confirm
) {
    await showLock.acquireAsync()
    return new Promise<DialogResult>(resolve => {
        state.on = true
        state.title = title
        state.level = type
        setDialogButtons(buttonType)
        state.message = message ?? ""
        state.resolve = resolve
    })
}

export function infoDialog(
    title: string,
    message = "",
    buttonType: DialogButtonTypeOrCustom = DialogButtonType.Confirm
) {
    return showDialog(title, message, DialogLevel.Info, buttonType)
}

export function successDialog(
    title: string,
    message = "",
    buttonType: DialogButtonTypeOrCustom = DialogButtonType.Confirm
) {
    return showDialog(title, message, DialogLevel.Success, buttonType)
}

export function warningDialog(
    title: string,
    message = "",
    buttonType: DialogButtonTypeOrCustom = DialogButtonType.Confirm
) {
    return showDialog(title, message, DialogLevel.Warning, buttonType)
}

export function errorDialog(
    title: string,
    message = "",
    buttonType: DialogButtonTypeOrCustom = DialogButtonType.Confirm
) {
    return showDialog(title, message, DialogLevel.Error, buttonType)
}

export function setMessage(message: string) {
    state.message = message
}
