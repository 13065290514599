import { UserManager, WebStorageStateStore, Log, User } from "oidc-client"
import jwtDecode, { JwtPayload } from "jwt-decode"
import { computed, reactive } from "@vue/composition-api"
import { env } from "@/env"
import { currentRoute } from "@/services/Router"
import lodash from "lodash"

Log.logger = console
Log.level = Log.INFO

export type Permission =
    | "WEB_VIEW_DASHBOARD"
    | "WEB_VIEW_MISSION"
    | "WEB_VIEW_ISSUE"
    | "WEB_VIEW_MISSION_TEMPLATE"
    | "WEB_VIEW_SPACE_OBJECT"
    | "WEB_FILL_MISSION"
    | "IDENTITY_MANAGE"
    | "SPACE_OBJECT_MODIFY"
    | "MISSION_ADD_MISSION"
    | "MISSION_MODIFY_TEMPLATE"
    | "ISSUE_MANAGE"

const userManager = new UserManager({
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: env.AUTHORITY,
    client_id: env.CLIENT_ID,
    redirect_uri: `${env.HOST}/callback.html`,
    silent_redirect_uri: `${env.HOST}/silent-renew.html`,
    response_type: "code",
    scope: "openid profile offline_access",
    post_logout_redirect_uri: env.HOST,
    filterProtocolClaims: true,
})

const state = reactive({
    user: null as User | null,
})

export interface AccessTokenPayload extends JwtPayload {
    client_id: string
    exp: number
    iat: number
    ids: string | string[]
    iss: string
    /** 帳號 */
    name: string
    names: string | string[]
    /** 使用者/團隊名稱 */
    username: string
    permissions?: null | string | string[]
    oi_au_id: string
    oi_prst: string
    oi_tkn_id: string
    scope: string
    sub: string
}

export const userProfile = computed(() => {
    if (!state.user) return null
    const payload = jwtDecode<AccessTokenPayload>(state.user.access_token)
    return {
        id: state.user.profile.sub,
        name: payload.username,
        account: payload.name,
        accounts:
            typeof payload.names === "string" ? [payload.names] : payload.names,
        ids: typeof payload.ids === "string" ? [payload.ids] : payload.ids,
        permissions: !payload.permissions
            ? []
            : typeof payload.permissions === "string"
            ? [payload.permissions]
            : payload.permissions,
    }
})

export function hasPermission(id: Permission): boolean {
    return userProfile.value?.permissions.includes(id) ?? false
}

export function hasAnyIdentity(...ids: string[]) {
    return lodash(userProfile.value?.ids ?? [])
        .intersection(ids)
        .some()
}

export const accessToken = computed(() => state.user?.access_token)

async function refreshToken() {
    try {
        const user = await userManager.signinSilent()
        state.user = user
        return user
    } catch (error) {
        userManager.clearStaleState()
        return null
    }
}

export async function getUser() {
    const user = await userManager.getUser()
    if (!!user && user.refresh_token && user.expires_in <= 60)
        return await refreshToken()

    state.user = user
    return user
}

userManager.events.addAccessTokenExpiring(() => {
    refreshToken()
})

export function login(returnPath?: string) {
    return userManager.signinRedirect({
        state: `${env.HOST}${returnPath ?? currentRoute.value?.path}`,
    })
}

export function logout() {
    return userManager.signoutRedirect()
}
