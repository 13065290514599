const base = {
    TITLE: process.env.VUE_APP_TITLE!,
    CLIENT_ID: process.env.VUE_APP_CLIENT_ID!,
    HOST: process.env.VUE_APP_HOST!,
    AUTHORITY: process.env.VUE_APP_AUTHORITY!,
    SPACE_OBJECT_SERVICE: process.env.VUE_APP_SPACE_OBJECT_SERVICE!,
    MISSION_SERVICE: process.env.VUE_APP_MISSION_SERVICE!,
    ACCOUNT_WEB: process.env.VUE_APP_ACCOUNT_WEB!,
    ISSUE_SERVICE: process.env.VUE_APP_ISSUE_SERVICE!,
    APP_RELEASE: process.env.VUE_APP_APP_RELEASE!,
    QR_CODE_LINK_PREFIX: process.env.VUE_APP_QR_CODE_LINK_PREFIX!,
    MQTT_BROKER_URL: process.env.VUE_APP_MQTT_BROKER_URL!,
    MQTT_BROKER_PORT: parseInt(process.env.VUE_APP_MQTT_BROKER_PORT!),
}

export const env = base
