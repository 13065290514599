import Vue from "vue"
import VueCompositionAPI from "@vue/composition-api"
Vue.use(VueCompositionAPI)
import App from "./App.vue"
import { router } from "./services/Router"
import vuetify from "./plugins/vuetify"
import "@fontsource/noto-sans-tc"
import "@fontsource/noto-mono"
import "@mdi/font/css/materialdesignicons.css"
import { getUser } from "@/services/AuthService"

Vue.config.productionTip = false

getUser().then(() => {
    new Vue({
        router,
        vuetify,
        render: h => h(App)
    }).$mount("#app")
})
