

















































import { computed, defineComponent } from "@vue/composition-api"
import { dialogState, closeDialog, DialogLevel } from "@/services/DialogService"
export default defineComponent({
    setup() {
        const showDialog = computed({
            get: () => dialogState.value.on,
            set: (value) => {
                if (!value) closeDialog("確認")
            },
        })
        const persistent = computed(
            () =>
                dialogState.value.buttons.length != 1 ||
                dialogState.value.buttons[0] != "確認"
        )
        return {
            showDialog,
            dialogState,
            closeDialog,
            persistent,
            DialogLevel,
        }
    },
})
