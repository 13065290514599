import { computed, reactive } from "@vue/composition-api"
const state = reactive({
    now: new Date().valueOf()
})

export const now = computed(() => state.now)

setInterval(() => {
    state.now = new Date().valueOf()
}, 1000)

export interface TimeRange {
    start: string | null
    end: string | null
}
