import dayjs from "dayjs"
import dayjszhtw from "dayjs/locale/zh-tw"
import relativeTime from "dayjs/plugin/relativeTime"
dayjs.locale(dayjszhtw)
dayjs.extend(relativeTime)

export function timeFormatter(value: dayjs.ConfigType, format: string) {
    const time = dayjs(value)
    format = format.replace("FROM_NOW", time.fromNow())
    return time.isValid() ? time.format(format) : "--"
}

export function timeFromNowFormatter(value: dayjs.ConfigType) {
    return dayjs(value).fromNow()
}

export function toJSON(source: any) {
    return JSON.stringify(source)
}

export function mileageNumberFormatter(value: number | null) {
    if (value === null) return "--"
    const m = Math.floor((value * 1000) % 1000)
        .toString()
        .padStart(3, "0")
    const k = Math.floor(value)
    return `${k}K+${m}`
}
