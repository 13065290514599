import Vue from "vue"
import CompositionAPI, { computed, reactive } from "@vue/composition-api"
Vue.use(CompositionAPI)
import VueRouter, { Route, RouteConfig } from "vue-router"
Vue.use(VueRouter)
import { title, backNav, layout } from "@/services/AppBarService"
import { userProfile, hasPermission, Permission } from "@/services/AuthService"

export interface RouteMeta {
    menu?: string
    title: string
    backNav?: boolean
    permission?: Permission
    allowAnonymous?: boolean
    menuLink?: string
    layout?: "DEFAULT" | "PRINT"
    group?: string
}

export const routes: Array<RouteConfig> = [
    {
        path: "/",
        redirect: () => {
            if (!userProfile.value) return "/public"
            if (hasPermission("WEB_VIEW_DASHBOARD")) return "/dashboard"
            if (hasPermission("WEB_VIEW_MISSION")) return "/mission"
            if (hasPermission("WEB_VIEW_ISSUE")) return "/issue"
            if (hasPermission("WEB_VIEW_SPACE_OBJECT")) return "/spaces"
            return "/forbid"
        },
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/views/Dashboard/Dashboard.vue"),
        meta: {
            menu: "系統通知",
            title: "系統通知",
            permission: "WEB_VIEW_DASHBOARD",
        } as RouteMeta,
    },
    {
        path: "/mission",
        name: "Mission",
        component: () => import("@/views/Mission/Mission.vue"),
        meta: {
            menu: "任務管理",
            title: "任務管理",
            menuLink: "/mission?state=0&hasRoleOnly=true",
            permission: "WEB_VIEW_MISSION",
        } as RouteMeta,
    },
    {
        path: "/mission/creator",
        name: "MissionCreator",
        component: () => import("@/views/Mission/Creator.vue"),
        meta: {
            title: "建立新任務",
            backNav: true,
            permission: "MISSION_ADD_MISSION",
        } as RouteMeta,
    },
    {
        path: "/mission/:id",
        name: "MissionDetail",
        component: () => import("@/views/Mission/MissionDetail.vue"),
        props: true,
        meta: {
            title: "任務檢視",
            backNav: true,
            permission: "WEB_VIEW_MISSION",
        } as RouteMeta,
    },
    {
        path: "/issue",
        name: "Issue",
        component: () => import("@/views/Issue/Issue.vue"),
        meta: {
            menu: "問題追蹤",
            title: "問題追蹤",
            permission: "WEB_VIEW_ISSUE",
        } as RouteMeta,
    },
    {
        path: "/issue/:id",
        name: "IssueDetail",
        component: () => import("@/views/Issue/IssueDetail.vue"),
        props: true,
        meta: {
            title: "問題檢視",
            backNav: true,
            permission: "WEB_VIEW_ISSUE",
        } as RouteMeta,
    },
    {
        path: "/mission-template",
        name: "MissionTemplate",
        component: () => import("@/views/MissionTemplate/MissionTemplate.vue"),
        meta: {
            menu: "任務範本管理",
            title: "任務範本管理",
            permission: "WEB_VIEW_MISSION_TEMPLATE",
        } as RouteMeta,
    },
    {
        path: "/mission-template/importer",
        name: "MissionTemplateImporter",
        component: () =>
            import("@/views/MissionTemplate/MissionTemplateImporter.vue"),
        meta: {
            title: "任務範本匯入",
            backNav: true,
            permission: "MISSION_MODIFY_TEMPLATE",
        } as RouteMeta,
    },
    {
        path: "/mission-template/place-checker",
        name: "MissionTemplatePlaceChecker",
        component: () =>
            import("@/views/MissionTemplate/MissionTemplatePlaceChecker.vue"),
        meta: {
            title: "任務範本空間設備有效性檢查",
            backNav: true,
            permission: "MISSION_MODIFY_TEMPLATE",
        } as RouteMeta,
    },
    {
        path: "/mission-template/:id",
        name: "MissionTemplateDetail",
        component: () =>
            import("@/views/MissionTemplate/MissionTemplateDetail.vue"),
        props: true,
        meta: {
            title: "任務範本檢視",
            backNav: true,
            permission: "WEB_VIEW_MISSION_TEMPLATE",
        } as RouteMeta,
    },
    {
        path: "/spaces",
        name: "Space",
        component: () => import("@/views/SpaceObject/SpaceObject.vue"),
        props: {
            type: "SPACE",
        },
        meta: {
            menu: "空間管理",
            title: "空間管理",
            permission: "WEB_VIEW_SPACE_OBJECT",
        } as RouteMeta,
    },
    {
        path: "/spaces/importer",
        name: "SpaceImporter",
        component: () => import("@/views/SpaceObject/SpaceObjectImporter.vue"),
        props: {
            type: "SPACE",
        },
        meta: {
            title: "空間匯入",
            backNav: true,
            permission: "SPACE_OBJECT_MODIFY",
        } as RouteMeta,
    },
    {
        path: "/spaces/remover",
        name: "SpaceRemover",
        component: () => import("@/views/SpaceObject/SpaceObjectRemover.vue"),
        props: {
            type: "SPACE",
        },
        meta: {
            title: "空間註銷",
            backNav: true,
            permission: "SPACE_OBJECT_MODIFY",
        } as RouteMeta,
    },
    {
        path: "/spaces/creator",
        name: "SpaceCreator",
        component: () => import("@/views/SpaceObject/SpaceObjectDetail.vue"),
        props: (r) => ({
            type: "SPACE",
        }),
        meta: {
            title: "空間新增",
            backNav: true,
            permission: "SPACE_OBJECT_MODIFY",
        } as RouteMeta,
    },
    {
        path: "/spaces/:id",
        name: "SpaceDetail",
        component: () => import("@/views/SpaceObject/SpaceObjectDetail.vue"),
        props: (r) => ({
            type: "SPACE",
            id: r.params.id,
        }),
        meta: {
            title: "空間異動",
            backNav: true,
            permission: "SPACE_OBJECT_MODIFY",
        } as RouteMeta,
    },
    {
        path: "/equips",
        name: "Equip",
        component: () => import("@/views/SpaceObject/SpaceObject.vue"),
        props: {
            type: "EQUIP",
        },
        meta: {
            menu: "設備管理",
            title: "設備管理",
            permission: "WEB_VIEW_SPACE_OBJECT",
        } as RouteMeta,
    },
    {
        path: "/equips/importer",
        name: "EquipImporter",
        component: () => import("@/views/SpaceObject/SpaceObjectImporter.vue"),
        props: {
            type: "EQUIP",
        },
        meta: {
            title: "設備匯入",
            backNav: true,
            permission: "SPACE_OBJECT_MODIFY",
        } as RouteMeta,
    },
    {
        path: "/equips/remover",
        name: "EquipRemover",
        component: () => import("@/views/SpaceObject/SpaceObjectRemover.vue"),
        props: {
            type: "Equip",
        },
        meta: {
            title: "設備註銷",
            backNav: true,
            permission: "SPACE_OBJECT_MODIFY",
        } as RouteMeta,
    },
    {
        path: "/equips/creator",
        name: "EquipCreator",
        component: () => import("@/views/SpaceObject/SpaceObjectDetail.vue"),
        props: (r) => ({
            type: "EQUIP",
        }),
        meta: {
            title: "設備新增",
            backNav: true,
            permission: "SPACE_OBJECT_MODIFY",
        } as RouteMeta,
    },
    {
        path: "/equips/:id",
        name: "EquipDetail",
        component: () => import("@/views/SpaceObject/SpaceObjectDetail.vue"),
        props: (r) => ({
            type: "EQUIP",
            id: r.params.id,
        }),
        meta: {
            title: "設備異動",
            backNav: true,
            permission: "SPACE_OBJECT_MODIFY",
        } as RouteMeta,
    },
    {
        path: "/qrcode",
        name: "QrCode",
        component: () => import("@/views/QrCode/QrCodeList.vue"),
        meta: {
            menu: "QR Code 管理",
            title: "QR Code 管理",
            permission: "SPACE_OBJECT_MODIFY",
        } as RouteMeta,
    },
    {
        path: "/qrcode/importer",
        name: "QrCodeImporter",
        component: () => import("@/views/QrCode/QrCodeBatch.vue"),
        meta: {
            title: "QR Code 匯入",
            backNav: true,
            permission: "SPACE_OBJECT_MODIFY",
        } as RouteMeta,
    },
    {
        path: "/qrcode/print",
        name: "QrCodePrint",
        component: () => import("@/views/QrCode/QrCodePrint.vue"),
        meta: {
            title: "QR Code 列印",
            permission: "SPACE_OBJECT_MODIFY",
            layout: "PRINT",
        } as RouteMeta,
    },
    {
        path: "/app-download",
        name: "AppDownload",
        component: () => import("@/views/AppDownload.vue"),
        meta: {
            menu: "行動客戶端",
            title: "行動客戶端",
            allowAnonymous: true,
        } as RouteMeta,
    },
    {
        path: "/manual",
        name: "Manual",
        component: () => import("@/views/Manual/CoreConcept.vue"),
        meta: {
            menu: "核心概念",
            title: "核心概念",
            group: "使用說明",
        } as RouteMeta,
    },
    {
        path: "/basicFunction",
        name: "BasicFunction",
        component: () => import("@/views/Manual/BasicFunction.vue"),
        meta: {
            menu: "基本功能",
            title: "基本功能",
            group: "使用說明",
        } as RouteMeta,
    },
    {
        path: "/basicFunction/import-place",
        name: "BasicFunctionImportPlace",
        component: () => import("@/views/Manual/ImportPlace.vue"),
        meta: {
            title: "基本功能 - 匯入空間設備",
            backNav: true,
        } as RouteMeta,
    },
    {
        path: "/mission-manual",
        name: "MissionManual",
        component: () => import("@/views/Manual/Mission.vue"),
        meta: {
            menu: "任務管理",
            title: "任務管理",
            group: "使用說明",
        } as RouteMeta,
    },
    {
        path: "/public",
        name: "Public",
        component: () => import("@/views/Public.vue"),
        meta: {
            title: "尚未登入",
            allowAnonymous: true,
        } as RouteMeta,
    },
    {
        path: "/forbid",
        name: "Forbid",
        component: () => import("@/views/Forbid.vue"),
        meta: {
            title: "禁止存取",
            allowAnonymous: true,
        } as RouteMeta,
    },
    {
        path: "*",
        name: "NotFound",
        component: () => import("@/views/NotFound.vue"),
        meta: {
            title: "找不到頁面",
            backNav: true,
            allowAnonymous: true,
        } as RouteMeta,
    },
]

export const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
})

const state = reactive({
    currentRoute: null as Route | null,
})

/** 當前路由 */
export const currentRoute = computed(() => state.currentRoute)

router.beforeEach(async (to, _, next) => {
    const meta = to.meta as RouteMeta | undefined
    const allowAnonymous = !!meta?.allowAnonymous
    if (userProfile.value) {
        if (to.name === "Public") {
            next({ path: "/" })
            return
        }
        if (!meta?.permission || hasPermission(meta.permission)) {
            next()
            return
        }
        next({ name: "Forbid" })
        return
    }
    if (allowAnonymous) {
        next()
        return
    }

    next({ name: "Public" })
})
// 記錄當前路由
router.afterEach((to) => {
    state.currentRoute = to
    title.value = to.meta?.title
    backNav.value = history.length === 1 ? false : to.meta?.backNav ?? false
    layout.value = to.meta?.layout ?? "DEFAULT"
})
