import Vue from "vue"
import CompositionAPI, { computed, reactive } from "@vue/composition-api"
import { env } from "@/env"
Vue.use(CompositionAPI)

const state = reactive({
    title: "",
    backNav: false,
    layout: "DEFALUT" as "DEFAULT" | "PRINT"
})

export const title = computed({
    get: () => state.title,
    set: value => {
        state.title = value
        document.title = value ? `${value} | ${env.TITLE}` : env.TITLE
    }
})

export const backNav = computed({
    get: () => state.backNav,
    set: value => {
        state.backNav = value
    }
})

export const layout = computed({
    get: () => state.layout,
    set: value => {
        state.layout = value
    }
})
