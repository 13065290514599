


















































import { defineComponent, reactive, toRefs } from "@vue/composition-api"
import { routes, RouteMeta } from "@/services/Router"
import { userProfile, hasPermission, Permission } from "@/services/AuthService"
import { env } from "@/env"

export default defineComponent({
    name: "AppNavigationMenu",
    setup() {
        const state = reactive({})
        const links = routes
            .filter((route) => {
                const meta = route.meta as RouteMeta | undefined
                return (
                    meta?.menu &&
                    (meta.allowAnonymous || userProfile.value) &&
                    (!meta.permission || hasPermission(meta.permission))
                )
            })
            .map((route) => ({
                to: route.path,
                name: route.meta!.menu as string,
                group: route.meta!.group as string | undefined,
            }))

        const groupedLinks = [] as {
            name: string
            to?: string
            children?: {
                name: string
                to: string
            }[]
        }[]

        links.forEach((link) => {
            if (!link.group) {
                groupedLinks.push({
                    name: link.name,
                    to: link.to,
                })
                return
            }

            const oldGroup = groupedLinks.find((g) => g.name === link.group)
            if (oldGroup) {
                oldGroup.children!.push({
                    name: link.name,
                    to: link.to,
                })
            } else {
                groupedLinks.push({
                    name: link.group,
                    children: [
                        {
                            name: link.name,
                            to: link.to,
                        },
                    ],
                })
            }
        })

        const externalLinks = [
            {
                name: "帳號管理",
                url: env.ACCOUNT_WEB,
                permission: <Permission>"IDENTITY_MANAGE",
            },
        ].filter((item) => hasPermission(item.permission))
        return {
            ...toRefs(state),
            groupedLinks,
            externalLinks,
        }
    },
})
