import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import zhHant from "vuetify/src/locale/zh-Hant"

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {}
    },
    lang: {
        locales: { zhHant },
        current: "zhHant"
    }
})

export interface SelectOption {
    text: string
    value: number | string | null
}
